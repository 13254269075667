
import { defineComponent, ref, computed, watch, onMounted, nextTick } from 'vue'
import type { PropType } from 'vue'
import { GlobalWorkerOptions, getDocument, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist'
// eslint-disable-next-line
// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import useWait from "@/utils/payment/useWait";
import { guid } from '@/utils'
import { b64toBlob } from '@/utils/claim/b64toBlob'

function base64DataToBinary(base64Data: string) {
  return atob(base64Data)
}

export default defineComponent({
  props: {
    base64Data: {
      type: String,
      default: ''
    },
    height: {
      type: [String, Number] as PropType<string|number>
    },
    width: {
      type: [String, Number] as PropType<string|number>
    }
  },
  setup(props) {
    const wait = useWait()
    const pdfViewerContainerId = guid()
    const pdfPreviewerContainerId = guid()
    const preview = ref(false)
    const previewUrl = ref('')

    let fixed = true

    const imageWidth = computed(() => {
      if (typeof props.width === 'number') {
        return `${props.width}px`
      }
      return props.width
    })

    const imageHeight = computed(() => {
      if (typeof props.height === 'number') {
        return `${props.height}px`
      }
      return props.height
    })

    const handlePreview = () => {
      fixed = true
      preview.value = true
      nextTick(() => {
        pdfRender(pdfPreviewerContainerId, base64DataToBinary(props.base64Data))
      })
    }

    const handleClose = () => {
      if (fixed) {
        preview.value = false
      }
    }

    onMounted(() => {
      wait.toRun?.()
    })

    const pdfRender = (containerId: string, data: string) => {
      const pdfViewContainer = document.getElementById(containerId)
      if (pdfViewContainer) {
        const children = pdfViewContainer.children
        for (let i = children.length - 1; i >= 0; i--) {
          pdfViewContainer.removeChild(children[i])
        }
      }
      GlobalWorkerOptions.workerSrc = pdfjsWorker
      const loadingTask = getDocument({ data })
      return loadingTask.promise.then(function(pdf: PDFDocumentProxy) {
        //
        // Fetch the first page
        //
        if (pdfViewContainer) {
          const pdfViewContainerWidth = pdfViewContainer.offsetWidth - 20
          const pdfViewContainerHeight = pdfViewContainer.offsetHeight - 20
          const pdfViewContainerRatio = pdfViewContainerWidth / pdfViewContainerHeight
          for (let i = 1; i <= pdf.numPages; i++) {
            pdf.getPage(i).then(function(page: PDFPageProxy) {
              let scale = 1
              let viewport = page.getViewport({ scale })
              const ratio = viewport.width / viewport.height
              if (pdfViewContainerRatio > ratio) {
                scale = pdfViewContainerHeight / viewport.height
              } else {
                scale = pdfViewContainerWidth / viewport.width
              }
              if (scale < 1) {
                viewport = page.getViewport({ scale })
              }
              //
              // Prepare canvas using PDF page dimensions
              //
              const canvas = document.createElement('canvas') as HTMLCanvasElement
              pdfViewContainer.appendChild(canvas)
              if (canvas) {
                const context = canvas.getContext('2d')
                canvas.height = viewport.height
                canvas.width = viewport.width

                //
                // Render PDF page into canvas context
                //
                const renderContext = {
                  canvasContext: context,
                  viewport: viewport,
                }
                page.render(renderContext as any)
              }
            })
          }
        }
      })
    }

    watch(() => props.base64Data, (base64Data) => {
      if (base64Data) {
        previewUrl.value = URL.createObjectURL(b64toBlob(base64Data, 'application/pdf'));

        wait.then(() => {
          pdfRender(pdfViewerContainerId, base64DataToBinary(base64Data))
        })
      }
    }, {
      immediate: true
    })

    return {
      pdfViewerContainerId,
      pdfPreviewerContainerId,
      preview,
      previewUrl,
      imageWidth,
      imageHeight,
      handlePreview,
      handleClose,
    }
  }
})
